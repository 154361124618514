<template>
  <section class="about-us">
    <div class="container--small">
      <div class="about-us__content">
        <div class="about-us__info">
          <div class="about-us__title main__title">
            О нас
          </div>
          <div class="about-us__text main__text">
            Комплекс исследований акваторий и технических осмотров ТЭЦ представляет собой систему отлаженной
            работы по выявлению неполадок и аварийных ситуаций у разных видов транспорта (наземного и
            водного) и у различных гидротехнических сооружений. Мы предоставляем качественные услуги
            используя современное оборудование, аналогов которых еще нет в Украине.
          </div>
        </div>
        <div class="about-us__image">
          <img
            src="@/assets/images/mechanic.jpg"
            class="about-img"
            alt="mechanic-image"
          >
        </div>
      </div>
    </div>
    <div class="container">
      <div class="about-services">
        <router-link
          :to="{ name:'LightCarPage' }"
          class="about-services__item about-services__item1"
        >
          <div class="services__item-content">
            <p class="services__item-title">
              Протоколы испытаний авто
            </p>
            <img
              src="@/assets/images/arrow.svg"
              alt="Arrow"
              class="services-img"
            >
          </div>
          <div class="services-filter" />
        </router-link>
        <router-link
          :to="{ name:'HeavyCarPage' }"
          class="about-services__item about-services__item2"
          style=""
        >
          <div class="services__item-content">
            <p class="services__item-title">
              ТО грузового транспорта
            </p>
            <img
              src="@/assets/images/arrow.svg"
              alt="Arrow"
              class="services-img"
            >
          </div>
        </router-link>
        <router-link
          :to="{ name:'BoatsPage' }"
          class="about-services__item about-services__item3"
          style=""
        >
          <div class="services__item-content">
            <p class="services__item-title">
              ТО водного транспорта
            </p>
            <img
              src="@/assets/images/arrow.svg"
              alt="Arrow"
              class="services-img"
            >
          </div>
        </router-link>
        <router-link
          :to="{ name:'BuildingsPage' }"
          class="about-services__item about-services__item4"
          style=""
        >
          <div class="services__item-content ">
            <p class="services__item-title">
              Проверка гидротехнических сооружений
            </p>
            <img
              src="@/assets/images/arrow.svg"
              alt="Arrow"
              class="services-img"
            >
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: 'HomeAboutUs'
}
</script>
